export default {
  "login": {
    "titolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi per guardare la diretta"])},
    "lostPsw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho dimenticato le credenziali"])}
  },
  "credenzialiSmarrite": {
    "testoIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l'email di registrazione e provvederemo a inviare nuovamente le credenziali."])},
    "pulsanteConfermaMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma Email"])},
    "alertErroreTitolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
    "alertErroreTesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mail inserita non è registrata nel nostro sistema per questo evento"])},
    "alertSuccessoTitolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Valida!"])},
    "alertSuccessoTesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiamo inviando alla tua email le credenziali di accesso."])}
  },
  "authAlerts": {
    "attenzioneAlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
    "concurrentConnessionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connessione non può essere effettuata da due dispositivi contemporaneamente. Si prega di disconnetterne uno e riprovare."])},
    "credenzialiNonValide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le credenziali fornite non sono corrette o l'utenza non è registrata al servizio. Si prega di ricontrollare la mail inviata con le credenziali o effettuare la registrazione al servizio."])}
  },
  "registrazione": {
    "titolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati al Servizio"])},
    "punto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Inserisci e convalida la tua mail:"])},
    "punto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto le "])},
    "punto21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["condizioni di utilizzo"])},
    "punto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Completa la registrazione effettuando il pagamento:"])},
    "modificaMailButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica Email"])},
    "confermaMailButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma Email"])}
  },
  "registrazioneAlerts": {
    "successoTitolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulazioni!"])},
    "attenzioneTitolo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
    "successoTesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La registrazione è avvenuta con successo. A breve le arriveranno le credenziali di accesso alla mail utilizzata durante la registrazione."])},
    "mailNonValidaTesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mail inserita non è valida o già associata ad un altro utente."])}
  },
  "descSpecial": {
    "introduzione1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per registrarsi al servizio streaming bisognerà seguire i passaggi illustrati nella sezione 'Registrati al Servizio'."])},
    "introduzione2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In particolare verrà richiesto di:"])},
    "elencoPunto1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire una mail valida a cui successivamente riceverete la vostra password"])},
    "elencoPunto2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettare le condizioni di utilizzo del sito"])},
    "elencoPunto3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizzare la registrazione tramite il pagamento. Il costo della diretta è di €"])},
    "conclusione1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In caso di mancata ricezione o smarrimento della mail contenente le credenziali di accesso, essa potrà essere richiesta nuovamente cliccando sul pulsante 'Ho dimenticato le credenziali' nella sezione di login."])},
    "conclusione2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo per ulteriori problemi potrete contattarci all'indirizzo mail"])},
    "conclusione3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie e buona visione."])}
  },
  "canaliLive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In diretta questa settimana"])},
  "canaliOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I nostri altri canali"])},
  "sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie ai nostri sponsor!"])}
}